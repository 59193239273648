<template>
  <div class="footer">
    <ul>
      <li v-for="(item, index) in navList" :key="index">
        <a target="_blank" rel="noopener" v-bind:href="item.link">{{
        item.title
      }}</a>
      </li>
    </ul>
    <p>
      <!-- <a href="https://docs.ep.sugarat.top/plan/log.html" target="_blank" rel="noopener">v{{ version }}</a>
      © 2019 - {{ currentYear }}  -->
      <!-- <a target="_blank" rel="noopener" href="https://docs.ep.sugarat.top/author.html">技术提供:粥里有勺糖</a> -->
    </p>
    <p class="beian">
      <a href="https://beian.miit.gov.cn/" target="_blank">鲁ICP备2023011057号-1</a>
    </p>
    <p class="shouquan">
      <a>v{{ version }} © 2019 - {{ currentYear }}</a>
      <a>授权:美育数字服务平台 技术提供:粥里有勺糖</a>
    </p>

   
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { version } from '../../../package.json'

const props = defineProps<{
  type?: 'home' | 'dashboard' | 'task'
}>()

const navList = computed(() => {
  const navMap = {
    home: [
      {
        title: '美育数字服务平台',
        link: 'https://www.qgmykj.com/serves.php'
      },
      // {
      //   title: 'GitHub',
      //   link: 'https://www.qgmykj.com/serves.php'
      // },
      {
        title: '赛事服务系统',
        link: 'https://www.qgmykj.com/myyxs.php'
      }
      // {
      //   title: '联系作者',
      //   link: 'https://docs.ep.sugarat.top/author.html'
      // },
      // {
      //   title: '请喝奶茶🧋',
      //   link: 'https://docs.ep.sugarat.top/praise/index.html'
      // }
    ],
    // dashboard: [
    //   {
    //     title: '页面&功能问题反馈，点这里😊',
    //     link: 'https://support.qq.com/product/444158'
    //   }
    // ],
    // task: [
    //   {
    //     title: '页面&功能问题反馈，点这里😊',
    //     link: 'https://support.qq.com/product/444158'
    //   }
    // ]
  }

  return navMap[props.type || 'home'] || []
})

const currentYear = new Date().getFullYear()
const fontColor = computed(() => {
  const colors = {
    home: '#FFFFFF',
    dashboard: '#7f7f7f',
    task: '#a4a4a4'
  }
  return colors[props.type || 'home']
})

const shadowColor = computed(() => {
  const colors = {
    home: '#FFFFFF',
    dashboard: '#9b9b9b',
    task: '#d5d5d5'
  }
  return colors[props.type || 'home']
})
</script>

<style lang="scss" scoped>
.footer {
  ul {
    // border: 1px solid red;
    margin: 10px auto;
    display: flex;
    justify-content: center;

    li {
      // border: 1px solid red;
      min-width: 200px;
      list-style: none;
      text-align: center;

      a {
        text-align: center;
        color: v-bind(fontColor);
        opacity: 0.8;
        font-size: 1rem;
        line-height: 1rem;

        &:hover {
          opacity: 1;
          text-shadow: 0 0 2px v-bind(shadowColor);
        }
      }
    }
  }

  p {
    margin-top: 8px;
    padding-bottom: 5px;
    color: v-bind(shadowColor);

    a {
      color: v-bind(shadowColor);
      margin-left: 10px;
    }

    // a:hover{
    //   color: red;
    // }
  }

  p {
    text-align: center;
  }
}
</style>
